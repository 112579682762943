export const prints = [
    {
        number: 1,
        fileName: "1.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 2,
        fileName: "2.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 3,
        fileName: "3.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 4,
        fileName: "4.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 5,
        fileName: "5.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 6,
        fileName: "6.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 7,
        fileName: "7.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 8,
        fileName: "8.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 9,
        fileName: "9.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 10,
        fileName: "10.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 11,
        fileName: "11.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 12,
        fileName: "12.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 13,
        fileName: "13.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 14,
        fileName: "14.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 15,
        fileName: "15.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 16,
        fileName: "16.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 17,
        fileName: "17.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 18,
        fileName: "18.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 19,
        fileName: "19.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 20,
        fileName: "20.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 21,
        fileName: "21.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 22,
        fileName: "22.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 23,
        fileName: "23.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 24,
        fileName: "24.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 25,
        fileName: "25.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 26,
        fileName: "26.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 27,
        fileName: "27.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 28,
        fileName: "28.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 29,
        fileName: "29.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 30,
        fileName: "30.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 31,
        fileName: "31.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 32,
        fileName: "32.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 33,
        fileName: "33.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 34,
        fileName: "34.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 35,
        fileName: "35.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 36,
        fileName: "36.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 37,
        fileName: "37.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 38,
        fileName: "38.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 39,
        fileName: "39.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 40,
        fileName: "40.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 41,
        fileName: "41.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 42,
        fileName: "42.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 43,
        fileName: "43.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 44,
        fileName: "44.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 45,
        fileName: "45.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 46,
        fileName: "46.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 47,
        fileName: "47.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 48,
        fileName: "48.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 49,
        fileName: "49.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 50,
        fileName: "50.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 51,
        fileName: "51.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 52,
        fileName: "52.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 53,
        fileName: "53.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 54,
        fileName: "54.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 55,
        fileName: "55.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 56,
        fileName: "56.jpg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 57,
        fileName: "57.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    },
    {
        number: 58,
        fileName: "58.jpg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: false,
        size: null
    }
];

export const misprints = [
    {
        number: 1,
        fileName: "mp1.jpg",
        isAvailable: true,
        isMisprint: true,
        isTshirt: false,
        size: null
    },
    {
        number: 2,
        fileName: "mp2.jpg",
        isAvailable: true,
        isMisprint: true,
        isTshirt: false,
        size: null
    },
    {
        number: 3,
        fileName: "mp3.jpg",
        isAvailable: true,
        isMisprint: true,
        isTshirt: false,
        size: null
    },
    {
        number: 4,
        fileName: "mp4.jpg",
        isAvailable: false,
        isMisprint: true,
        isTshirt: false,
        size: null
    },
    {
        number: 5,
        fileName: "mp5.jpg",
        isAvailable: false,
        isMisprint: true,
        isTshirt: false,
        size: null
    },
    {
        number: 6,
        fileName: "mp6.jpg",
        isAvailable: false,
        isMisprint: true,
        isTshirt: false,
        size: null
    },
    {
        number: 7,
        fileName: "mp7.jpg",
        isAvailable: true,
        isMisprint: true,
        isTshirt: false,
        size: null
    },
    {
        number: 8,
        fileName: "mp8.jpg",
        isAvailable: true,
        isMisprint: true,
        isTshirt: false,
        size: null
    },
    {
        number: 9,
        fileName: "mp9.jpg",
        isAvailable: true,
        isMisprint: true,
        isTshirt: false,
        size: null
    }
];

export const tshirts = [
    {
        number: 1,
        fileName: "t1.jpeg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: true,
        size: "S"
    },
    {
        number: 2,
        fileName: "t2.jpeg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: true,
        size: "M"
    },
    {
        number: 3,
        fileName: "t3.jpeg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: true,
        size: "L"
    },
    {
        number: 4,
        fileName: "t4.jpeg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: true,
        size: "XL"
    },
    {
        number: 5,
        fileName: "t5.jpeg",
        isAvailable: true,
        isMisprint: false,
        isTshirt: true,
        size: "S"
    },
    {
        number: 6,
        fileName: "t6.jpeg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: true,
        size: "M"
    },
    {
        number: 7,
        fileName: "t7.jpeg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: true,
        size: "L"
    },
    {
        number: 8,
        fileName: "t8.jpeg",
        isAvailable: false,
        isMisprint: false,
        isTshirt: true,
        size: "XL"
    }
];


